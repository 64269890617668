.learn-more-container{
   border-top: 1.5px solid rgba(0,0,0,0.15);
   display: flex;
   justify-content: space-between;
   align-items: center;
   margin-bottom: 3rem;
}

.learn-more-next{
  margin-right: 6px;
  color: rgba(0,0,0,0.5);
  font-family: 'Work Sans', sans-serif;
  font-size: 1.1rem;
}

.learn-more-title{
  font-family: 'EB Garamond', serif;
  font-size: 1.3rem;
  margin-bottom: 1.25rem;
}

.learn-more-description{
  width: 60%;
  font-weight: 400;
  font-size: 1rem;
  margin-top: 0;
}

.learn-more-link{
  text-decoration: none;
  color: #000;
  width: 140px;
}

.learn-more-link-text{
  font-size: 1.2rem;
  font-weight: 400;
}