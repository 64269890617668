.donations-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.image-gallery{
  height: 400px;
}

.photo-gallery-subtext{
  margin-top: 4px;
}

.donations-gallery{
  width: 100%;
  height: auto;
}

@media only screen and (max-width: 428px){
  .image-gallery{
    height: 150px;
  }     
}