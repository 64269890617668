.community-container{
  display: flex;
  flex-direction: column;
}

.community-title{
  font-size: 5rem;
  text-align: center;
  margin-bottom: 0.5rem;
}

.community-image{
  padding-right: 3.5rem;
  max-width: 300px;
  min-width: 300px;  
}

.community-name{
  font-size: 3.25rem;
  position: relative;
  display: inline;
  background-image: linear-gradient(to right, rgb(246,228,213), rgb(246,228,213));
  background-position: 2rem 2.4rem;
  background-repeat: repeat-x;
  background-size: 10px 15px;
}

.community-card-container{
  display: flex;
  margin: 5rem 0;
  box-shadow: 15px 0px 15px rgba(246, 228, 213, 0.5);
  padding: 0;
  padding-right: 1rem;
  box-sizing: border-box;
}

.community-icons-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 100%;
  margin-left: 2rem;
}

.community-location-container{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 1.5rem 0;
}

.community-text-container{
  box-sizing: border-box;
  padding: 2rem 0;
}

