.footer-container{
  background-color: #000000;
  position: relative;
  width: 100vw;
  bottom: 0;
  left: calc(var(--body-side-margin) * -1);
  padding: 2rem var(--body-side-margin);
  box-sizing: border-box;
}

.footer-text-container{
  display: flex;
  flex-direction: row;
}

.footer-text-left{
  color: rgba(255,255,255,0.5);
  margin-right: 2rem;
  min-width: 120px;
  margin: 0.75rem 0;
}

.footer-text-right{
  color: #FFF;
  margin: 0.75rem 0;
  margin-right: 2rem;
}

.footer-bottom-container{
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin: 2rem 0 1rem 0;
}

.footer-bottom-text-container{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.footer-bottom-text{
  color: #FFF;
  margin: 0 1.5rem;
  font-size: 0.9rem;
}

.footer-icon{
  width: 24px;
  height: 24px;
}

.footer-bottom-linkedin{
  width: 24px;
  height: 24px;
  margin-right: 25px;
}

.footer-bottom-instagram{
  width: 24px;
  height: 24px;
  border-radius: 4px;
  margin-right: 25px;
}

.footer-bottom-facebook{
  width: 24px;
  height: 24px;
  border-radius: 4px;
  margin-right: 25px;
}


@media only screen and (max-width: 428px){
  .footer-bottom-text-container{
    flex-direction: column;
  }

  .footer-bottom-container{
    flex-direction: column;
    align-items: center;
  }
  .footer-bottom-text-container{
    margin-bottom: 15px;
  }
  .team-member-name{
    align-items: center;
  }
  .footer-bottom-facebook{
    margin-right: 0;
  }
}
