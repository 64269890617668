.header-container{
  display: flex;
  width: 100%;
  top: 0;
  left: 0;
  border-bottom: 1px solid #000000;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0px;
}

.header-options-container{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.header-button-dropdown{
  padding: 0 1.5rem;
}

.header-button{
  border: 2px solid #000000;
  margin-left: 1rem;
  height: fit-content;
  color: #000000;
  text-decoration: none;
  transition: 0.25 linear;
}

.header-button-font{
  margin: 0;
  padding: 0.65rem 1rem;
  color: #000000;
  text-decoration: none;
}

.header-button:hover .header-button-font{
  color: rgba(0, 0, 0, 0.55);
  transition: 0.25s;
}

.header-button:hover{
  border-color: rgba(0, 0, 0, 0.55);
  transition: 0.25s;
}

@media only screen and (max-width: 428px){

  .header-container{
    flex-direction: column;

  }
  
.header-options-container{
  flex-direction: column;
}
}