@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:wght@400;600&display=swap');
@import "~react-image-gallery/styles/css/image-gallery.css";

:root {
  --body-side-margin: 9rem;
}

body {
  margin: 0;
  padding: 0rem var(--body-side-margin);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input, button, submit { border:none; } 

button, input[type="submit"], input[type="reset"] {
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

button {
  all: unset;
  color: blue;
  -webkit-text-fill-color: blue;
  cursor: pointer;
}

a{
  text-decoration: none;
}

button:focus {
  outline: orange 5px auto;
}

h1{
  font-family: 'EB Garamond', serif;
}

h2{
  font-family: 'Work Sans', sans-serif;
}

h3{
  font-family: 'EB Garamond', serif;
}

h4{
  font-family: 'Work Sans', sans-serif;
  font-weight: 700;
  font-size: 0.95rem;
}

p{
  font-family: 'Work Sans', sans-serif;
}



@media only screen and (max-width: 428px){
  :root {
    --body-side-margin: 2rem;
  }

}