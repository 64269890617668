@import url(https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=EB+Garamond:wght@400;600&display=swap);
:root {
  --body-side-margin: 9rem;
}

body {
  margin: 0;
  padding: 0rem 9rem;
  padding: 0rem var(--body-side-margin);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input, button, submit { border:none; } 

button, input[type="submit"], input[type="reset"] {
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

button {
  all: unset;
  color: blue;
  -webkit-text-fill-color: blue;
  cursor: pointer;
}

a{
  text-decoration: none;
}

button:focus {
  outline: orange 5px auto;
}

h1{
  font-family: 'EB Garamond', serif;
}

h2{
  font-family: 'Work Sans', sans-serif;
}

h3{
  font-family: 'EB Garamond', serif;
}

h4{
  font-family: 'Work Sans', sans-serif;
  font-weight: 700;
  font-size: 0.95rem;
}

p{
  font-family: 'Work Sans', sans-serif;
}



@media only screen and (max-width: 428px){
  :root {
    --body-side-margin: 2rem;
  }

}
.header-container{
  display: flex;
  width: 100%;
  top: 0;
  left: 0;
  border-bottom: 1px solid #000000;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0px;
}

.header-options-container{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.header-button-dropdown{
  padding: 0 1.5rem;
}

.header-button{
  border: 2px solid #000000;
  margin-left: 1rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  color: #000000;
  text-decoration: none;
  transition: 0.25 linear;
}

.header-button-font{
  margin: 0;
  padding: 0.65rem 1rem;
  color: #000000;
  text-decoration: none;
}

.header-button:hover .header-button-font{
  color: rgba(0, 0, 0, 0.55);
  transition: 0.25s;
}

.header-button:hover{
  border-color: rgba(0, 0, 0, 0.55);
  transition: 0.25s;
}

@media only screen and (max-width: 428px){

  .header-container{
    flex-direction: column;

  }
  
.header-options-container{
  flex-direction: column;
}
}
.footer-container{
  background-color: #000000;
  position: relative;
  width: 100vw;
  bottom: 0;
  left: calc(var(--body-side-margin) * -1);
  padding: 2rem var(--body-side-margin);
  box-sizing: border-box;
}

.footer-text-container{
  display: flex;
  flex-direction: row;
}

.footer-text-left{
  color: rgba(255,255,255,0.5);
  margin-right: 2rem;
  min-width: 120px;
  margin: 0.75rem 0;
}

.footer-text-right{
  color: #FFF;
  margin: 0.75rem 0;
  margin-right: 2rem;
}

.footer-bottom-container{
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin: 2rem 0 1rem 0;
}

.footer-bottom-text-container{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.footer-bottom-text{
  color: #FFF;
  margin: 0 1.5rem;
  font-size: 0.9rem;
}

.footer-icon{
  width: 24px;
  height: 24px;
}

.footer-bottom-linkedin{
  width: 24px;
  height: 24px;
  margin-right: 25px;
}

.footer-bottom-instagram{
  width: 24px;
  height: 24px;
  border-radius: 4px;
  margin-right: 25px;
}

.footer-bottom-facebook{
  width: 24px;
  height: 24px;
  border-radius: 4px;
  margin-right: 25px;
}


@media only screen and (max-width: 428px){
  .footer-bottom-text-container{
    flex-direction: column;
  }

  .footer-bottom-container{
    flex-direction: column;
    align-items: center;
  }
  .footer-bottom-text-container{
    margin-bottom: 15px;
  }
  .team-member-name{
    align-items: center;
  }
  .footer-bottom-facebook{
    margin-right: 0;
  }
}

.learn-more-container{
   border-top: 1.5px solid rgba(0,0,0,0.15);
   display: flex;
   justify-content: space-between;
   align-items: center;
   margin-bottom: 3rem;
}

.learn-more-next{
  margin-right: 6px;
  color: rgba(0,0,0,0.5);
  font-family: 'Work Sans', sans-serif;
  font-size: 1.1rem;
}

.learn-more-title{
  font-family: 'EB Garamond', serif;
  font-size: 1.3rem;
  margin-bottom: 1.25rem;
}

.learn-more-description{
  width: 60%;
  font-weight: 400;
  font-size: 1rem;
  margin-top: 0;
}

.learn-more-link{
  text-decoration: none;
  color: #000;
  width: 140px;
}

.learn-more-link-text{
  font-size: 1.2rem;
  font-weight: 400;
}
.homepage-container{
  display: flex;
  flex-direction: column;
}

.homepage-landing-container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 8% 0;
  border-bottom: 1.5px solid rgba(0,0,0,0.15);
  box-sizing: border-box;
}

.homepage-landing-image{
  width: 45%;
  padding-left: 10%;
}

.homepage-landing-title{
  font-size: 3rem;
  margin-bottom: 0.5rem;
}

.homepage-landing-title-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.homepage-landing-text-underline{
  position: relative;
  display: inline;
  background-image: linear-gradient(to right, rgb(246,228,213), rgb(246,228,213));
  background-position: 2rem 2.4rem;
  background-repeat: repeat-x;
  background-size: 10px 15px;
}

.homepage-landing-subtitle{
  font-weight: 400;
  font-size: 1rem;
  color: #282828;
}

.homepage-mission-container{
  padding: 1rem 0;
  display: flex;
  flex-direction: column;

}

.homepage-mission-title{
  width: 85%;
  font-size: 2.25rem;
  color: #282828;
}

.homepage-mission-body-container{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 5rem 0;
}

.homepage-mission-image{
  width: 45%;
  margin-right: calc(var(--body-side-margin) * -1);
  padding-left: 10%;
}

.homepage-mission-text-underline{
  position: relative;
  display: inline;
  background-image: linear-gradient(to right, #E5E5E5, #E5E5E5);
  background-position: 0rem 0.6rem;
  background-repeat: repeat-x;
  background-size: 30px 10px;
  padding-bottom: 1rem;
  font-weight: 700;
  color: #282828;
}

.homepage-card-crop{
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  
}

.homepage-mission-body-text{
  margin-top: 2rem;
}

.homepage-card-container{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 21%;
  max-width: 400px;
  margin-top: 2rem;
}

.homepage-cards-container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1rem;
  margin-bottom: 6rem;
}

.homepage-card-image{
  width: 100%;
  margin: 1rem 0;
}

.homepage-card-title{
  font-weight: 400;
  font-size: 1rem;
  text-decoration: underline;
  margin: 0.25rem 0 0.5rem 0;
}

.homepage-card-description{
  margin-top: 0.25rem;
  font-weight: 400;
  font-size: 0.8rem;
}

@media only screen and (max-width: 428px){
  .homepage-landing-container{
    flex-direction: column;

  }

  .homepage-mission-body-container{

    flex-direction: column;
  }

  .homepage-cards-container{
    flex-direction: column;
  }
 
  .learn-more-container{
    flex-direction: column;
  }
 
  .homepage-mission-image{
    width: 100%;
    margin-right: 0;
    padding-left: 0;
  }

  .homepage-card-container{
 
    width: 100%;
   
  }

  .homepage-landing-image{
    width: 100%;
    padding-left: 0%;
  }
  }
.initiatives-container{
  display: flex;
  flex-direction: column;
}

.initiatives-title{
  font-size: 3rem;
  padding-bottom: 4rem;
  border-bottom: 1.5px solid rgba(0,0,0,0.15);
}

.initiatives-component{
  padding-bottom: 1rem;
}

.initiatives-border-bottom{
  border-bottom: 1.5px solid rgba(0,0,0,0.15)
}

.initiatives-all :last-child{
  border-bottom: 0;
}

.initiatives-component-title{
  font-size: 2rem;
}

.initiatives-top-image{
  width: 100%;
  margin-top: 90px;
}

.initiatives-link{
  color: blue;
}
.donations-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.image-gallery{
  height: 400px;
}

.photo-gallery-subtext{
  margin-top: 4px;
}

.donations-gallery{
  width: 100%;
  height: auto;
}

@media only screen and (max-width: 428px){
  .image-gallery{
    height: 150px;
  }     
}
.community-container{
  display: flex;
  flex-direction: column;
}

.community-title{
  font-size: 5rem;
  text-align: center;
  margin-bottom: 0.5rem;
}

.community-image{
  padding-right: 3.5rem;
  max-width: 300px;
  min-width: 300px;  
}

.community-name{
  font-size: 3.25rem;
  position: relative;
  display: inline;
  background-image: linear-gradient(to right, rgb(246,228,213), rgb(246,228,213));
  background-position: 2rem 2.4rem;
  background-repeat: repeat-x;
  background-size: 10px 15px;
}

.community-card-container{
  display: flex;
  margin: 5rem 0;
  box-shadow: 15px 0px 15px rgba(246, 228, 213, 0.5);
  padding: 0;
  padding-right: 1rem;
  box-sizing: border-box;
}

.community-icons-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 100%;
  margin-left: 2rem;
}

.community-location-container{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 1.5rem 0;
}

.community-text-container{
  box-sizing: border-box;
  padding: 2rem 0;
}


.team-container{
    padding-top: 2rem;
    flex-direction: column;
    display: flex;
}

.team-title{
    font-size: 3.5rem;
    margin-bottom: 1rem;
}

.team-members-container :first-child{
    margin: 0;
}

.team-member-container{
    margin: 0 2rem;
    width: 270px;
}

.regular-members-container{
    cursor: pointer;
    /* margin: 0 2rem; */
    margin-top: 3rem;
    display: flex;

}

.regular-members-container :first-child{
    margin: 0;
}

.exec-container{
    display: flex;
    justify-content: space-between;
}

.team-members-container{
    display: flex;
    flex-direction: row;
    cursor: pointer;
    flex-wrap: wrap;
}

.team-member-image-container{
    max-width: 270px;
    min-width: 240px;
    background-color: #F6E4D5;
}

.team-body-section{
    display: flex;
    flex-direction: row;
    padding-bottom: 3rem;
}

.team-body-text-container{
    width: 50%;
}

.team-member-image-main{
    width: 100%;
}

.team-member-name{
    font-size: 1.6rem;
    font-family: 'EB Garamond';
    margin: 0.5rem 0;
    transition: 0.25s linear;
}

.team-member-title{
    font-weight: 400;
    font-size: 1rem;
    margin: 0.25rem 0;
}

.team-member-container:hover .team-member-name{
    color: rgba(0, 0, 0, 0.55);
    transition: 0s;
}



@media only screen and (max-width: 428px){

  .team-container{
    flex-direction: column;
     }

    .team-members-container{
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
  
    .team-body-section{
        flex-direction: column;
    }

    .regular-members-container{
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin-top: 0;
    }

    .team-body-text-container{
        text-align: center;
        display: flex;
        width: 100%;
        margin-top: 2rem;
    }
  
   
}
.team-member-top-bar{
  display: flex;
  flex-direction: row;
  padding: 2rem 0;
}

.team-member-top-bar-back{
  padding: 0;
  margin: 0;
}

.team-member-top-bar-back-font{
  padding-right: 4rem;
  border-right: 1px solid rgba(0,0,0,0.55);
  margin: 0;
  font-weight: 400;
}

.team-member-top-bar-members{
  padding-left: 3rem;
  display: flex;
  flex-direction: row;
}

.team-member-link{
  margin: 0;
  padding: 0 1rem;
  font-weight: 400;
}

.team-member-underline{
  text-decoration: underline;
  font-weight: 700;
}

.team-background-box{
  position: relative;
  background-color: #F6E4D5;
  width: 100%;
  height: 100%;
  z-index: 0;
  left: calc(var(--body-side-margin) * -1);
}

.team-member-image-container-detail{
  max-width: 500px;
  min-width: 400px;
  height: 450px;
  overflow: visible;
}

.team-member-image{
  height: 100%;
  position: relative;
  right: -5rem;
  bottom: 0;
}

.team-member-details-container{
  display: flex;
  margin-top: 5rem;
  margin-bottom: 0;
}

.team-member-description{
  display: flex;
  flex-direction: column;
  width: 50%;
  min-width: 500px;
  margin-left: 4rem;
}

.team-member-name-detail{
  font-size: 5rem;
  margin-bottom: 0rem;
}

.team-member-title-detail{
  font-size: 1rem;
  margin-bottom: 1.25rem;
  margin-top: 0.3rem;
}

@media only screen and (max-width: 428px){
.team-member-details-container{
  flex-direction: column;
}

.team-member-description{
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 0px;
  margin-left: 0rem;
}

.team-member-image{
  height: 100%;
  position: relative;
  right: 0rem;
  bottom: 0;
}
}
.partner-container{
    display: flex;
    flex-direction: row;
}

.partner-form-title{
    font-size: 4.5rem;
    margin-top: 8rem;
}

.partner-image{
    max-width: 50%;
    min-width: 40%;
    box-sizing: border-box;
    padding: 3rem 0 3rem 3rem;    
}

/*Input text boxes*/
.form-group {
  margin-bottom: 2rem;
  margin-left: 1rem;
  margin-right: 1rem;
}

.contactForm .form-control {
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 1);
  border-radius: 0;
  padding: 0; 
}

#contactForm .error {
  color: red;
  font-size: 12px; 
}
select {
  -webkit-appearance: none;
}
select option{
  background-color: #82caff;
}

::-webkit-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: black;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: black;
  opacity: 1; /* Firefox */
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: black;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: black;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: black;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  font-size: 1rem;
  line-height: 1.5;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; 
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0; 
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
#form-message-warning, #form-message-success {
  display: none;
}

#form-message-warning {
  color: red;
}

.col-md-6 {
  flex: 0 0 50%;
  max-width: 50%; 
}

.col-md-12 {
  flex: 0 0 100%;
  max-width: 100%; 
}

.btn {
  padding: 12px 16px;
  cursor: pointer;
  border-width: 1px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 400;
  position: relative;
  margin-bottom: 20px;
  transition: 0.3s; 
  color:black;
}
@media (prefers-reduced-motion: reduce) 
{
  .btn {
  transition: none; 
  } 
}

.btn.btn-primary {
  border: 1px solid black;
  border-radius: 0;
  width: 20em;  
  height: 2em;
  background: white !important;
  border-color: black !important;
  color:black; 
}
.btn.btn-primary:hover, .btn.btn-primary:focus {
  border-color: black !important;
  background: black !important; 
  color:white;
}


@media only screen and (max-width: 428px){

  .partner-container {
    flex-direction: column;
  }
  
  .partner-image{
    padding: 3rem 0 3rem 0rem;
    max-width: 100%;
    max-height: 30%;
  }

  .partner-form-title {
    font-size: 3rem;
    margin-top: 0.5rem;
}
.header-button{
  margin-left: 0rem;
}
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

a{
  color: #000000;
  text-decoration: none;
  transition: 0.25s linear;
}

a:hover {
  color: rgba(0,0,0,0.55);
  transition: none;
}

button{
  text-decoration: none;
  color: #000000;
}
