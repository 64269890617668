.team-container{
    padding-top: 2rem;
    flex-direction: column;
    display: flex;
}

.team-title{
    font-size: 3.5rem;
    margin-bottom: 1rem;
}

.team-members-container :first-child{
    margin: 0;
}

.team-member-container{
    margin: 0 2rem;
    width: 270px;
}

.regular-members-container{
    cursor: pointer;
    /* margin: 0 2rem; */
    margin-top: 3rem;
    display: flex;

}

.regular-members-container :first-child{
    margin: 0;
}

.exec-container{
    display: flex;
    justify-content: space-between;
}

.team-members-container{
    display: flex;
    flex-direction: row;
    cursor: pointer;
    flex-wrap: wrap;
}

.team-member-image-container{
    max-width: 270px;
    min-width: 240px;
    background-color: #F6E4D5;
}

.team-body-section{
    display: flex;
    flex-direction: row;
    padding-bottom: 3rem;
}

.team-body-text-container{
    width: 50%;
}

.team-member-image-main{
    width: 100%;
}

.team-member-name{
    font-size: 1.6rem;
    font-family: 'EB Garamond';
    margin: 0.5rem 0;
    transition: 0.25s linear;
}

.team-member-title{
    font-weight: 400;
    font-size: 1rem;
    margin: 0.25rem 0;
}

.team-member-container:hover .team-member-name{
    color: rgba(0, 0, 0, 0.55);
    transition: 0s;
}



@media only screen and (max-width: 428px){

  .team-container{
    flex-direction: column;
     }

    .team-members-container{
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
  
    .team-body-section{
        flex-direction: column;
    }

    .regular-members-container{
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin-top: 0;
    }

    .team-body-text-container{
        text-align: center;
        display: flex;
        width: 100%;
        margin-top: 2rem;
    }
  
   
}