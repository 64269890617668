.team-member-top-bar{
  display: flex;
  flex-direction: row;
  padding: 2rem 0;
}

.team-member-top-bar-back{
  padding: 0;
  margin: 0;
}

.team-member-top-bar-back-font{
  padding-right: 4rem;
  border-right: 1px solid rgba(0,0,0,0.55);
  margin: 0;
  font-weight: 400;
}

.team-member-top-bar-members{
  padding-left: 3rem;
  display: flex;
  flex-direction: row;
}

.team-member-link{
  margin: 0;
  padding: 0 1rem;
  font-weight: 400;
}

.team-member-underline{
  text-decoration: underline;
  font-weight: 700;
}

.team-background-box{
  position: relative;
  background-color: #F6E4D5;
  width: 100%;
  height: 100%;
  z-index: 0;
  left: calc(var(--body-side-margin) * -1);
}

.team-member-image-container-detail{
  max-width: 500px;
  min-width: 400px;
  height: 450px;
  overflow: visible;
}

.team-member-image{
  height: 100%;
  position: relative;
  right: -5rem;
  bottom: 0;
}

.team-member-details-container{
  display: flex;
  margin-top: 5rem;
  margin-bottom: 0;
}

.team-member-description{
  display: flex;
  flex-direction: column;
  width: 50%;
  min-width: 500px;
  margin-left: 4rem;
}

.team-member-name-detail{
  font-size: 5rem;
  margin-bottom: 0rem;
}

.team-member-title-detail{
  font-size: 1rem;
  margin-bottom: 1.25rem;
  margin-top: 0.3rem;
}

@media only screen and (max-width: 428px){
.team-member-details-container{
  flex-direction: column;
}

.team-member-description{
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 0px;
  margin-left: 0rem;
}

.team-member-image{
  height: 100%;
  position: relative;
  right: 0rem;
  bottom: 0;
}
}