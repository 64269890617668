.partner-container{
    display: flex;
    flex-direction: row;
}

.partner-form-title{
    font-size: 4.5rem;
    margin-top: 8rem;
}

.partner-image{
    max-width: 50%;
    min-width: 40%;
    box-sizing: border-box;
    padding: 3rem 0 3rem 3rem;    
}

/*Input text boxes*/
.form-group {
  margin-bottom: 2rem;
  margin-left: 1rem;
  margin-right: 1rem;
}

.contactForm .form-control {
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 1);
  border-radius: 0;
  padding: 0; 
}

#contactForm .error {
  color: red;
  font-size: 12px; 
}
select {
  -webkit-appearance: none;
}
select option{
  background-color: #82caff;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: black;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: black;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: black;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  font-size: 1rem;
  line-height: 1.5;
  -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  -o-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out; 
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0; 
}
.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
#form-message-warning, #form-message-success {
  display: none;
}

#form-message-warning {
  color: red;
}

.col-md-6 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%; 
}

.col-md-12 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%; 
}

.btn {
  padding: 12px 16px;
  cursor: pointer;
  border-width: 1px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 400;
  position: relative;
  margin-bottom: 20px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s; 
  color:black;
}
@media (prefers-reduced-motion: reduce) 
{
  .btn {
  -webkit-transition: none;
  -o-transition: none;
  transition: none; 
  } 
}

.btn.btn-primary {
  border: 1px solid black;
  border-radius: 0;
  width: 20em;  
  height: 2em;
  background: white !important;
  border-color: black !important;
  color:black; 
}
.btn.btn-primary:hover, .btn.btn-primary:focus {
  border-color: black !important;
  background: black !important; 
  color:white;
}


@media only screen and (max-width: 428px){

  .partner-container {
    flex-direction: column;
  }
  
  .partner-image{
    padding: 3rem 0 3rem 0rem;
    max-width: 100%;
    max-height: 30%;
  }

  .partner-form-title {
    font-size: 3rem;
    margin-top: 0.5rem;
}
.header-button{
  margin-left: 0rem;
}
}

